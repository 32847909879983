import type {IUser} from '@bidmii/common/lib/server/IServerApi';
import {selector} from 'recoil';

export const localStorageUserState = selector<IUser | null>({
  key: 'localStorageUserState',
  get: () => {
    const userString = localStorage.getItem('user');
    return userString ? JSON.parse(userString) : null;
  },
  set: (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  },
});
