// Re-introduce when we have fixed unit tests
// const getRequiredEnvProperty = (propertyName) => {
//   if (process.env[propertyName] === undefined) {
//     throw new Error(`Environment variable '${propertyName}' is not set`);
//   }
//   return process.env[propertyName];
// };

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const API_URL = `${SERVER_URL}v1.2`;
export const CDN_URL = process.env.REACT_APP_CDN_URL;
export const GMAPS_API_KEY = process.env.REACT_APP_GMAPS_API_KEY;
export const REQUEST_PROTO = process.env.REACT_APP_HTTP_PROTO;
export const REQUEST_PORT = process.env.REACT_APP_HTTP_PORT;
export const GOOGLE_API_CLIENT_ID = process.env.REACT_APP_GOOGLE_API_CLIENT_ID;
export const CATEGORY_IDS = process.env.REACT_APP_CATEGORY_IDS;
export const MARKETING_URL = process.env.REACT_APP_MARKETING_URL; //getRequiredEnvProperty('REACT_APP_MARKETING_URL');
export const FACEBOOK_ID =
  process.env.REACT_APP_FACEBOOK_ID || '283090469458929';
export const APPLE_ID = process.env.REACT_APP_APPLE_ID || '1544660765';
export const PACKAGE_ID = process.env.REACT_APP_PACKAGE_ID || 'com.bidmii';
export const DEPLOYMENT_ENV = process.env.REACT_APP_DEPLOYMENT_ENV;

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
};
