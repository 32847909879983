import {atom} from 'recoil';

export const removeHeaderState = atom({
  key: 'removeHeaderState',
  default: false,
});

export const removeFooterState = atom({
  key: 'removeFooterState',
  default: false,
});

export const removeDrawerState = atom({
  key: 'removeDrawerState',
  default: false,
});

export const removeMainScrollState = atom({
  key: 'removeMainScrollState',
  default: false,
});
