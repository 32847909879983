import {fetchRequest} from '@bidmii/common/lib/util/FetchRequest';
import {API_URL} from '../constants';

export const initUserFromWebView = (token: $TSFixMe, callback: $TSFixMe) => {
  fetchRequest(`${API_URL}/user`, {
    headers: {
      'Content-Type': 'application/json',
      'Firebase-Authorization': token,
    },
  })
    .then((res) => res.json())
    .then((res) => {
      res.initialised = true;
      // localStorage.setItem(
      //   'user',
      //   JSON.stringify({
      //     id: res.id,
      //     name: res.name,
      //     userType: res.userType,
      //     imgAttachmentRef: res.imgAttachmentRef,
      //     hasAgreedToTerms: res.hasAgreedToTerms,
      //     lat: res.lat,
      //     lng: res.lng,
      //     _links: res._links,
      //   }),
      // );
      callback(res);
    });
};

export const webViewLog = (msg: $TSFixMe) => {
  if ((window as $TSFixMe).ReactNativeWebView) {
    (window as $TSFixMe).ReactNativeWebView.postMessage(JSON.stringify(msg));
  }
};
