import React, {useEffect, useState} from 'react';
import * as Sentry from '@sentry/react';

const loadHomePage = () =>
  import(/* webpackChunkName: "HomePage" */ '../modules/Home/HomePage');

export const LazyHomePage: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadHomePage}>
      {(Module: any) =>
        Module.HomePage ? <Module.HomePage {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadMessagingCenter = () =>
  import(/* webpackChunkName: "MessagingCenter" */ '../modules/Messaging');

export const LazyMessagingCenter: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadMessagingCenter}>
      {(Module: any) =>
        Module.MessagingIndex ? <Module.MessagingIndex {...props} /> : null
      }
    </LazyLoader>
  );
};
const loadEditProfile = () =>
  import(
    /* webpackChunkName: "EditProfile" */ '../modules/Profile/EditProfile/EditProfile'
  );

export const LazyEditProfile: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadEditProfile}>
      {(Module: any) =>
        Module.EditProfile ? <Module.EditProfile {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadPublicProfileRouter = () =>
  import(
    /* webpackChunkName: "PublicProfileRouter" */ '../modules/Profile/PublicProfile/PublicProfileRouter'
  );

export const LazyPublicProfileRouter: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadPublicProfileRouter}>
      {(Module: any) =>
        Module.PublicProfileRouter ? (
          <Module.PublicProfileRouter {...props} />
        ) : null
      }
    </LazyLoader>
  );
};
const loadRequestForProposalsList = () =>
  import(
    /* webpackChunkName: "RequestForProposalsList" */ '../modules/ListViews/RequestForProposalsList'
  );

export const LazyRequestForProposalsList: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadRequestForProposalsList}>
      {(Module: any) =>
        Module.RequestForProposalsList ? (
          <Module.RequestForProposalsList {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadVerificationWizard = () =>
  import(
    /* webpackChunkName: "VerificationWizard" */ '../modules/IdentityVerification/VerificationWizard'
  );

export const LazyVerificationWizard: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadVerificationWizard}>
      {(Module: any) =>
        Module.VerificationWizard ? (
          <Module.VerificationWizard {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadArticlesList = () =>
  import(
    /* webpackChunkName: "ArticlesList" */ '../modules/HelpArticles/HelpArticlesList'
  );

export const LazyArticlesList: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadArticlesList}>
      {(Module: any) =>
        Module.HelpArticlesList ? <Module.HelpArticlesList {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadHelpArticle = () =>
  import(
    /* webpackChunkName: "HelpArticle" */ '../modules/HelpArticles/HelpArticle'
  );

export const LazyHelpArticle: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadHelpArticle}>
      {(Module: any) =>
        Module.HelpArticle ? <Module.HelpArticle {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadCKEditor = () =>
  import(/* webpackChunkName: "CKEditor" */ './CKEditor');

export const LazyCKEditor: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadCKEditor}>
      {(Module: any) =>
        Module.CKEditor ? (
          <Module.CKEditor
            {...props}
            style={{width: window.outerWidth <= 600 ? '100%' : 500}}
          />
        ) : null
      }
    </LazyLoader>
  );
};
const loadInsuranceQualifications = () =>
  import(
    /* webpackChunkName: "InsuranceQualificationsDialog" */ './dialogs/InsuranceQualifications'
  );

export const LazyInsuranceQualifications: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadInsuranceQualifications}>
      {(Module: any) =>
        Module.InsuranceQualifications ? (
          <Module.InsuranceQualifications {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadSubscriptionsCheckout = () =>
  import(
    /* webpackChunkName: "SubscriptionsCheckout" */ '../modules/BidmiiProSubscriptions/SubscriptionsCheckout'
  );

export const LazySubscriptionsCheckout: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadSubscriptionsCheckout}>
      {(Module: any) =>
        Module.SubscriptionsCheckout ? (
          <Module.SubscriptionsCheckout {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadAccountingAnalytics = () =>
  import(
    /* webpackChunkName: "AccountingAnalytics" */ '../modules/ContractorAnalytics/AccountingAnalytics'
  );

export const LazyAccountingAnalytics: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadAccountingAnalytics}>
      {(Module: any) =>
        Module.AccountingAnalytics ? (
          <Module.AccountingAnalytics {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadProfileSettings = () =>
  import(
    /* webpackChunkName: "ProfileSettings" */ '../modules/Profile/EditProfile/components/ProfileSettings/ProfileSettings'
  );

export const LazyProfileSettings: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadProfileSettings}>
      {(Module: any) =>
        Module.ProfileSettings ? <Module.ProfileSettings {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadGrantLandingPage = () =>
  import(
    /* webpackChunkName: "GrantLandingPage" */ '../modules/BidmiiProSubscriptions/CDAP/GrantLandingPage'
  );

export const LazyGrantLandingPage: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadGrantLandingPage}>
      {(Module: any) =>
        Module.GrantLandingPage ? <Module.GrantLandingPage {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadRequestQuoteDialog = () =>
  import(
    /* webpackChunkName: "RequestQuoteDialog" */ '../modules/ProWebsite/components/RequestQuoteDialog'
  );

export const LazyRequestQuoteDialog: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadRequestQuoteDialog}>
      {(Module: any) =>
        Module.RequestQuoteDialog ? (
          <Module.RequestQuoteDialog {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadBidmiiProLandingPage = () =>
  import(
    /* webpackChunkName: "BidmiiProLandingPage" */ '../modules/BidmiiProSubscriptions/LandingPage/BidmiiProLandingPage'
  );

export const LazyBidmiiProLandingPage: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadBidmiiProLandingPage}>
      {(Module: any) =>
        Module.BidmiiProLandingPage ? (
          <Module.BidmiiProLandingPage {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadContractorSearchList = () =>
  import(
    /* webpackChunkName: "ContractorSearchList" */ '../modules/FindContractors/ContractorSearchList'
  );

export const LazyContractorSearchList: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadContractorSearchList}>
      {(Module: any) =>
        Module.ContractorSearchList ? (
          <Module.ContractorSearchList {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadVerificationStep2 = () =>
  import(
    /* webpackChunkName: "VerificationStep2" */ '../modules/IdentityVerification/components/VerificationStep2'
  );

export const LazyVerificationStep2: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadVerificationStep2}>
      {(Module: any) =>
        Module.VerificationStep2 ? (
          <Module.VerificationStep2 {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadVerificationStep = () =>
  import(
    /* webpackChunkName: "VerificationStep" */ '../modules/BidmiiProSubscriptions/ProSetup/steps/VerificationSetup'
  );

export const LazyVerificationStep: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadVerificationStep}>
      {(Module: any) =>
        Module.VerificationStep ? <Module.VerificationStep {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadNewProjectWizard = () =>
  import(
    /* webpackChunkName: "NewProjectWizard" */ '../modules/NewProjectWizard/NewProjectWizard'
  );

export const LazyNewProjectWizard: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadNewProjectWizard}>
      {(Module: any) =>
        Module.NewProjectWizard ? <Module.NewProjectWizard {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadResourcesListView = () =>
  import(
    /* webpackChunkName: "ResourcesListView" */ '../modules/ResourcesMarketplace/ResourcesListView'
  );

export const LazyResourcesListView: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadResourcesListView}>
      {(Module: any) =>
        Module.ResourcesListView ? (
          <Module.ResourcesListView {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadFAQPage = () =>
  import(/* webpackChunkName: "FAQPage" */ '../modules/FAQPage/FAQPage');

export const LazyFAQPage: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadFAQPage}>
      {(Module: any) => (Module.FAQPage ? <Module.FAQPage {...props} /> : null)}
    </LazyLoader>
  );
};

const loadProWebsite = () =>
  import(
    /* webpackChunkName: "ProWebsite" */ '../modules/ProWebsite/ProWebsite'
  );

export const LazyProWebsite: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadProWebsite}>
      {(Module: any) =>
        Module.ProWebsite ? <Module.ProWebsite {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadCostBreakdown = () =>
  import(
    /* webpackChunkName: "CostBreakdown" */ '../modules/ProposalForm/BidDetails/CostBreakdown'
  );

export const LazyCostBreakdown: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadCostBreakdown}>
      {(Module: any) =>
        Module.CostBreakdown ? <Module.CostBreakdown {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadReviewWizard = () =>
  import(
    /* webpackChunkName: "ReviewWizard" */ '../modules/ReviewWizard/ReviewWizard'
  );

export const LazyReviewWizard: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadReviewWizard}>
      {(Module: any) =>
        Module.ReviewWizard ? <Module.ReviewWizard {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadSupport = () =>
  import(/* webpackChunkName: "Support" */ '../modules/Support');

export const LazySupport: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadSupport}>
      {(Module: any) => (Module.Support ? <Module.Support {...props} /> : null)}
    </LazyLoader>
  );
};
const loadCategoriesList = () =>
  import(
    /* webpackChunkName: "CategoriesList" */ '../modules/FindContractors/CategoriesList'
  );

export const LazyCategoriesList: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadCategoriesList}>
      {(Module: any) =>
        Module.CategoriesList ? <Module.CategoriesList {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadTrialSetup = () =>
  import(
    /* webpackChunkName: "TrialSetup" */ '../modules/BidmiiProSubscriptions/ProSetup/TrialSetup'
  );

export const LazyTrialSetup: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadTrialSetup}>
      {(Module: any) =>
        Module.TrialSetup ? <Module.TrialSetup {...props} /> : null
      }
    </LazyLoader>
  );
};
const loadLocationsList = () =>
  import(
    /* webpackChunkName: "LocationsList" */ '../modules/FindContractors/LocationsList'
  );

export const LazyLocationsList: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadLocationsList}>
      {(Module: any) =>
        Module.LocationsList ? <Module.LocationsList {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadSupportPopup = () =>
  import(
    /* webpackChunkName: "SupportPopup" */ '../modules/Support/SupportPopup'
  );

export const LazySupportPopup: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadSupportPopup}>
      {(Module: any) =>
        Module.SupportPopup ? <Module.SupportPopup {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadMain = () =>
  import(/* webpackChunkName: "Main" */ '../modules/Main/Main');

export const LazyMain: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadMain}>
      {(Module: any) => (Module.Main ? <Module.Main {...props} /> : null)}
    </LazyLoader>
  );
};
const loadMarketing = () =>
  import(
    /* webpackChunkName: "Marketing" */ '../modules/Marketing/MarketingPage'
  );

export const LazyMarketingPage: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadMarketing}>
      {(Module: any) =>
        Module.MarketingPage ? <Module.MarketingPage {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadMainContainer = () =>
  import(
    /* webpackChunkName: "MainContainer" */ '../modules/Main/MainContainer'
  );

export const LazyMainContainer: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadMainContainer}>
      {(Module: any) =>
        Module.MainContainer ? <Module.MainContainer {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadLogin = () =>
  import(/* webpackChunkName: "Login" */ '../modules/Login/Login');

export const LazyLogin: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadLogin}>
      {(Module: any) => (Module.Login ? <Module.Login {...props} /> : null)}
    </LazyLoader>
  );
};

const loadContactsCRM = () =>
  import(
    /* webpackChunkName: "ContactsCRM" */ '../modules/ContactsCRM/ContactsCRM'
  );

export const LazyContactsCRM: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadContactsCRM}>
      {(Module: any) =>
        Module.ContactsCRM ? <Module.ContactsCRM {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadOnboardingWizard = () =>
  import(
    /* webpackChunkName: "OnboardingWizard" */ '../modules/Onboarding/OnboardingWizard'
  );

export const LazyOnboardingWizard: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadOnboardingWizard}>
      {(Module: any) =>
        Module.OnboardingWizard ? <Module.OnboardingWizard {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadSubscriptionPlans = () =>
  import(
    /* webpackChunkName: "SubscriptionPlans" */ '../modules/BidmiiProSubscriptions/SubscriptionPlans'
  );

export const LazySubscriptionPlans: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadSubscriptionPlans}>
      {(Module: any) =>
        Module.SubscriptionPlans ? (
          <Module.SubscriptionPlans {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadNewUserWizard = () =>
  import(
    /* webpackChunkName: "NewUserWizard" */ '../modules/NewUserWizard/NewUserWizard'
  );

export const LazyNewUserWizard: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadNewUserWizard}>
      {(Module: any) =>
        Module.NewUserWizard ? <Module.NewUserWizard {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadProSetup = () =>
  import(
    /* webpackChunkName: "ProSetup" */ '../modules/BidmiiProSubscriptions/ProSetup/ProSetup'
  );

export const LazyProSetup: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadProSetup}>
      {(Module: any) =>
        Module.ProSetup ? <Module.ProSetup {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadInvoicesTable = () =>
  import(
    /* webpackChunkName: "InvoicesTable" */ '../modules/Invoices/InvoicesTable'
  );

export const LazyInvoicesTable: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadInvoicesTable}>
      {(Module: any) =>
        Module.InvoicesTable ? <Module.InvoicesTable {...props} /> : null
      }
    </LazyLoader>
  );
};
const loadContractorDashboard = () =>
  import(
    /* webpackChunkName: "ContractorDashboard" */ '../modules/Home/ContractorDashboard'
  );

export const LazyContractorDashboard: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadContractorDashboard}>
      {(Module: any) =>
        Module.ContractorDashboard ? (
          <Module.ContractorDashboard {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadDesignmiiLeadForm = () =>
  import(
    /* webpackChunkName: "DesignmiiLeadForm" */ '../modules/ResourcesMarketplace/LeadForms/DesignmiiLeadForm'
  );

export const LazyDesignmiiLeadForm: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadDesignmiiLeadForm}>
      {(Module: any) =>
        Module.DesignmiiLeadForm ? (
          <Module.DesignmiiLeadForm {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadPaymentFlow = () =>
  import(/* webpackChunkName: "PaymentFlow" */ './PaymentFlow');

export const LazyPaymentFlow: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadPaymentFlow}>
      {(Module: any) =>
        Module.PaymentFlow ? <Module.PaymentFlow {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadBidmiiLottieWrapper = () =>
  import(
    /* webpackChunkName: "BidmiiLottieWrapper" */ '../common/BidmiiLottieWrapper'
  );

export const LazyBidmiiLottieWrapper: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadBidmiiLottieWrapper}>
      {(Module: any) =>
        Module.BidmiiLottieWrapper ? (
          <Module.BidmiiLottieWrapper {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadPrePackagedProjects = () =>
  import(
    /* webpackChunkName: "PrePackagedProjects" */ '../modules/NewProjectWizard/TemplateProjects/PrePackagedProjects'
  );

export const LazyPrePackagedProjects: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadPrePackagedProjects}>
      {(Module: any) =>
        Module.PrePackagedProjects ? (
          <Module.PrePackagedProjects {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadNewProjectStepOne = () =>
  import(
    /* webpackChunkName: "NewProjectStepOne" */ '../modules/NewProjectWizard/NewProjectStepOne'
  );

export const LazyNewProjectStepOne: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadNewProjectStepOne}>
      {(Module: any) =>
        Module.NewProjectStepOne ? (
          <Module.NewProjectStepOne {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadEditRequestForProposal = () =>
  import(
    /* webpackChunkName: "EditRequestForProposal" */ '../modules/ListViews/components/EditRequestForProposal'
  );

export const LazyEditRequestForProposal: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadEditRequestForProposal}>
      {(Module: any) =>
        Module.EditRequestForProposal ? (
          <Module.EditRequestForProposal {...props} />
        ) : null
      }
    </LazyLoader>
  );
};

const loadProposalForm = () =>
  import(
    /* webpackChunkName: "ProposalForm" */ '../modules/ProposalForm/ProposalForm'
  );

export const LazyProposalForm: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadProposalForm}>
      {(Module: any) =>
        Module.ProposalForm ? <Module.ProposalForm {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadMyRfpDialog = () =>
  import(
    /* webpackChunkName: "MyRfpDialog" */ '../modules/ListViews/components/DetailDialogs/MyRfpDialog'
  );

export const LazyMyRfpDialog: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadMyRfpDialog}>
      {(Module: any) =>
        Module.MyRfpDialog ? <Module.MyRfpDialog {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadCommunityDialog = () =>
  import(
    /* webpackChunkName: "CommunityDialog" */ '../modules/ListViews/components/DetailDialogs/CommunityDialog'
  );

export const LazyCommunityDialog: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadCommunityDialog}>
      {(Module: any) =>
        Module.CommunityDialog ? <Module.CommunityDialog {...props} /> : null
      }
    </LazyLoader>
  );
};

const loadContractorDialog = () =>
  import(
    /* webpackChunkName: "ContractorDialog" */ '../modules/ListViews/components/DetailDialogs/ContractorDialog'
  );

export const LazyContractorDialog: React.FC<any> = (props) => {
  return (
    <LazyLoader loadImport={loadContractorDialog}>
      {(Module: any) =>
        Module.ContractorDialog ? <Module.ContractorDialog {...props} /> : null
      }
    </LazyLoader>
  );
};

interface IProps {
  loadImport: () => Promise<any>;
  children?: (Module: any) => React.ReactNode;
}

const LazyLoader: React.FC<IProps> = (props) => {
  const [_module, setModule] = useState<any>(null);
  const [_error, setError] = useState<any>(null);
  const {loadImport} = props;

  useEffect(() => {
    loadImport()
      .then((mod: any) => {
        process.env.NODE_ENV === 'development' && console.log('mod', mod);
        setModule(
          // handle both es imports and cjs
          mod.default ? mod.default : mod,
        );
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' &&
          console.error('Failed to load module', err);
        Sentry.withScope(function (scope) {
          scope.setTag('section', 'LazyLoader.tsx useEffect()');
          Sentry.captureException(err);
        });
        setError(err);
      });
  }, [loadImport]);

  if (_module) {
    const t: string = typeof props.children;
    if (props.children && t === 'function') {
      return (props.children as (module: any) => JSX.Element)(_module);
    }
  }
  if (_error) {
    return (
      <div
        className="screen__container"
        style={{padding: '16px;', textAlign: 'center'}}>
        We have had an issue loading part of the application. <br />
        Please refresh your browser window
      </div>
    );
  }
  return <div className="screen__container" />;
};
