import {waitForMe} from './Utils';

let currentToken: string | null = null;
let currentTokenExpiry: Date | null = null;
let defaultHeaders: {[key: string]: string} = {};
let getIdToken: ((forceRefresh?: boolean) => Promise<string>) | null = null;

const backoffMs: number[] = [500, 1000, 2000];

export const resetSecuredFetchRequest = (
  fn: ((forceRefresh?: boolean) => Promise<string>) | null,
) => {
  console.log('resetSecuredFetchRequest');
  currentToken = null;
  currentTokenExpiry = null;
  getIdToken = fn;
};

export const setDefaultHeaders = (headers: {[key: string]: string}) => {
  defaultHeaders = headers;
};

const getFirebaseToken = async (url: string) => {
  if (!getIdToken) {
    console.error(url);
    throw Error(
      'You must provide a getIdToken via the `resetSecuredFetchRequest` function',
    );
  }
  if (
    !currentToken ||
    !currentTokenExpiry ||
    Date.now() > currentTokenExpiry.getTime()
  ) {
    console.log('Creating new token');
    currentToken = await getIdToken(true);
    currentTokenExpiry = new Date(Date.now() + 1000 * 60 * 60);
  }
  return currentToken;
};

/**
 * Returns Fetch() instance
 *
 * @param url {String}
 * @param options {Object}
 */

const noLogs = [
  'https://beta-api.bidmii.com/v1.1/users/online',
  'https://beta-api.bidmii.com/v1.1/my/notifications',
];

export const fetchRequest = async (url: string, options: RequestInit = {}) => {
  let retries = 0;
  const executeRequest = async (): Promise<Response> => {
    try {
      const res = await fetch(url, options);
      if (!res.ok) {
        // Todo, determine if we should retry based on error code
        throw new Error(`${res.status} ${res.statusText}`);
      }
      return res;
    } catch (err: any) {
      const failedToFetch =
        err?.message && err.message.includes('Failed to fetch');

      if (retries < backoffMs.length && failedToFetch === true) {
        console.log(
          '[fetchRequest] error and retry',
          retries,
          backoffMs[retries],
          err,
        );
        await waitForMe(backoffMs[retries]);
        retries++;
        return await executeRequest();
      } else {
        console.log('[fetchRequest] error', url, ':>>', err);
        throw err;
      }
    }
  };

  if (!noLogs.includes(url)) {
    if (url.indexOf('v1.2/') === -1) {
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error(`MISSING V1.2 PREFIX: ${options.method || 'GET'} ${url}`);
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
      console.error('DANGER DANGER DANGER DANGER');
    } else {
      console.log(`${options.method || 'GET'} ${url}`);
    }
  }
  options.headers = {
    ...(options.headers || {}),
    ...defaultHeaders,
  };

  return await executeRequest();
};

export const securedFetchRequest = async (
  url: string,
  options: RequestInit = {},
) => {
  const token = await getFirebaseToken(url);
  options.headers = {
    ...(options.headers || {}),
    'Firebase-Authorization': token,
  };
  return await fetchRequest(url, options);
};
