import {BuildEnvironment} from '../server/IServerApi';
import {FastifyRequest} from 'fastify';

interface IMetaData {
  title: string;
  link?: React.DetailedHTMLProps<
    React.LinkHTMLAttributes<HTMLLinkElement>,
    HTMLLinkElement
  >[];
  meta?: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[];
  script?: React.DetailedHTMLProps<
    React.ScriptHTMLAttributes<HTMLScriptElement>,
    HTMLScriptElement
  >[];
}

interface IMetaDataConfig {
  onServer: boolean;
  appUrl: string;
  cdnUrl: string;
  facebookAppId: string;
  appleAppStoreId: string;
  androidPackage: string;
  appName: string;
  nativeAppLaunchUrl: string;
  environment: BuildEnvironment;
}

let config: IMetaDataConfig | null = null;

export const initializeMetaData = (configuration: IMetaDataConfig) => {
  config = configuration;
};

export const getMetaData = (
  req: FastifyRequest,
  path: string,
  title?: string,
  description?: string,
  imageUrl?: string,
  json_ld?: string,
) => {
  if (!config) {
    throw new Error('MetaDataUtils not initialized');
  }
  const nativeAppUrl = `${config.nativeAppLaunchUrl}/${path}`;
  return metaDataObject(
    req,
    config.onServer,
    title ?? 'Bidmii | Get it done',
    description ??
      'Bidmii helps you find local contractors with verified reviews, get multiple free quotes, and holds payments in trust.',
    title ?? 'Bidmii | Get it done',
    `${config.appUrl}${path}`,
    imageUrl ?? `${config.cdnUrl}media/web_assets/share_logo.png`,
    config.facebookAppId,
    config.appleAppStoreId,
    config.androidPackage,
    config.appName,
    nativeAppUrl,
    config.environment,
    json_ld,
  );
};

const metaDataObject = (
  req: FastifyRequest,
  onServer: boolean,
  title: string,
  description: string,
  openGraphTitle: string,
  url: string,
  imageUrl: string,
  facebookAppId: string,
  appleAppStoreId: string,
  androidPackage: string,
  appName: string,
  nativeAppUrl: string,
  environment: BuildEnvironment,
  json_ld?: string,
): IMetaData => {
  const helmetAttribute = onServer ? {'data-rh': 'true'} : {};
  const indexing: React.DetailedHTMLProps<
    React.MetaHTMLAttributes<HTMLMetaElement>,
    HTMLMetaElement
  >[] =
    environment !== 'PRODUCTION'
      ? [
          {
            name: 'robots',
            content: 'noindex',
            ...helmetAttribute,
          },
          {
            name: 'googlebot',
            content: 'noindex',
            ...helmetAttribute,
          },
        ]
      : [];
  let canonicalUrl = url;
  if (url.includes('app.bidmii')) {
    canonicalUrl = url.replace('app.bidmii', 'bidmii');
  } else if (req.headers.proxieddomain) {
    canonicalUrl = `https://${req.headers.proxieddomain}${req.url}`;
  } else {
    canonicalUrl = url;
  }
  return {
    title: title,
    link: [
      {
        rel: 'canonical',
        href: canonicalUrl,
        ...helmetAttribute,
      },
    ],
    meta: [
      ...indexing,
      {
        name: 'description',
        content: description,
        ...helmetAttribute,
      },
      {
        name: 'apple-itunes-app',
        content: `app-id=${appleAppStoreId}, app-argument=${nativeAppUrl}`,
        ...helmetAttribute,
      },
      {
        property: 'fb:app_id',
        content: facebookAppId,
        ...helmetAttribute,
      },
      {
        property: 'og:title',
        content: openGraphTitle,
        ...helmetAttribute,
      },
      {
        property: 'og:description',
        content: description,
        ...helmetAttribute,
      },
      {
        property: 'og:type',
        content: 'website',
        ...helmetAttribute,
      },
      {
        property: 'og:url',
        content: url,
        ...helmetAttribute,
      },
      {
        property: 'og:image',
        content: imageUrl,
        ...helmetAttribute,
      },
      {
        property: 'og:image:secure_url',
        content: imageUrl,
        ...helmetAttribute,
      },
      {
        property: 'al:ios:url',
        content: nativeAppUrl,
        ...helmetAttribute,
      },
      {
        property: 'al:ios:app_store_id',
        content: appleAppStoreId,
        ...helmetAttribute,
      },
      {
        property: 'al:ios:app_name',
        content: appName,
        ...helmetAttribute,
      },
      {
        property: 'al:android:url',
        content: nativeAppUrl,
        ...helmetAttribute,
      },
      {
        property: 'al:android:package',
        content: androidPackage,
        ...helmetAttribute,
      },
      {
        property: 'al:android:app_name',
        content: appName,
        ...helmetAttribute,
      },
      {
        name: 'twitter:title',
        content: title,
        ...helmetAttribute,
      },
      {
        name: 'twitter:image',
        content: imageUrl,
        ...helmetAttribute,
      },
      {
        name: 'twitter:site',
        content: '@bidmii',
        ...helmetAttribute,
      },
      {
        name: 'twitter:card',
        content: 'summary',
        ...helmetAttribute,
      },
      {
        name: 'twitter:description',
        content: description,
        ...helmetAttribute,
      },
    ],
    script: [
      {
        type: 'application/ld+json',
        src: json_ld,
      },
    ],
  };
};

export const toMetaDataString = (metaData: IMetaData) => {
  let metaString = `<title>${metaData.title}</title>`;
  if (metaData.link) {
    metaString += metaData.link
      .map(
        (link) =>
          `<link ${Object.keys(link)
            .map((k) => `${k}="${(link as any)[k] as string}" `)
            .join('')}/>`,
      )
      .join('');
  }
  if (metaData.meta) {
    metaString += metaData.meta
      .map(
        (meta) =>
          `<meta ${Object.keys(meta)
            .map((k) => `${k}="${(meta as any)[k] as string}" `)
            .join('')}/>`,
      )
      .join('');
  }
  if (metaData.script) {
    metaString += metaData.script
      .map((script) =>
        script &&
        script.src &&
        script.src !== undefined &&
        script.src !== 'undefined'
          ? `<script type="${script.type}">${script.src}</script>`
          : '',
      )
      .join('');
  }
  return metaString;
};
