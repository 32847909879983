import {createTheme, adaptV4Theme} from '@mui/material/styles';

const AeonikProRegular = "'AeonikProReqular', sans-serif";
const TiemposTextRegular = "'TiemposTextRegular', serif";

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    blue: {
      main: string;
      dark: string;
      light: string;
    };
    green: {
      main: string;
      dark: string;
      light: string;
    };
    purple: {
      main: string;
      dark: string;
      light: string;
    };
    pink: {
      main: string;
      dark: string;
      light: string;
    };
    red: {
      main: string;
      dark: string;
      light: string;
    };
    gray: {
      light: string;
      main: string;
      border: string;
      medium: string;
      text: string;
      dark: string;
    };
  }
  interface PaletteOptions {
    blue: {
      main: string;
      dark: string;
      light: string;
    };
    green: {
      main: string;
      dark: string;
      light: string;
    };
    purple: {
      main: string;
      dark: string;
      light: string;
    };
    pink: {
      main: string;
      dark: string;
      light: string;
    };
    red: {
      main: string;
      dark: string;
      light: string;
    };
    gray: {
      light: string;
      main: string;
      border: string;
      medium: string;
      text: string;
      dark: string;
    };
  }
}

export const theme = createTheme(
  adaptV4Theme({
    palette: {
      // black
      primary: {
        main: '#000000',
        light: '#242424',
      },
      // white
      secondary: {
        main: '#FFFFFF',
      },
      blue: {
        main: '#22ABF7',
        dark: '#078EDA',
        light: '#D6EFFF',
      },
      green: {
        main: '#15A05B',
        dark: '#128C4F',
        light: '#DCF1DF',
      },
      purple: {
        main: '#A373E8',
        dark: '#8C50E2',
        light: '#F5F0FE',
      },
      pink: {
        main: '#FFA0F7',
        dark: '#F97BEF',
        light: '#FFEBFD',
      },
      red: {
        main: '#FF432E',
        dark: '#DA1C06',
        light: '#FFE5E4',
      },
      gray: {
        light: '#F7F7F7',
        main: '#E6E6E6',
        border: '#D9D9D9',
        medium: '#B3B3B3',
        text: '#999999',
        dark: '#717171',
      },
    },

    typography: {
      fontFamily: AeonikProRegular,

      body1: {
        fontFamily: TiemposTextRegular,
      },
      body2: {
        fontFamily: TiemposTextRegular,
      },
      button: {
        fontFamily: AeonikProRegular,
      },
      caption: {
        fontFamily: TiemposTextRegular,
      },
      overline: {
        fontFamily: TiemposTextRegular,
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 601,
        md: 1024,
        lg: 1440,
        xl: 1920,
      },
    },
  }),
);
