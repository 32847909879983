import React, {useEffect, useState} from 'react';
import {initializeApp} from 'firebase/app';
import {getPerformance} from 'firebase/performance';
import {getAuth} from 'firebase/auth';
import {
  getFirestore,
  collection,
  doc,
  setDoc,
  onSnapshot,
} from 'firebase/firestore';
import {
  getRemoteConfig,
  isSupported,
  fetchAndActivate,
  getValue,
} from 'firebase/remote-config';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {ThemeProvider, Theme, StyledEngineProvider} from '@mui/material/styles';
import {useRecoilState, useRecoilValue} from 'recoil';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import * as firebaseui from 'firebaseui';
import {
  setDefaultHeaders,
  fetchRequest,
  securedFetchRequest,
} from '@bidmii/common/lib/util/FetchRequest';
import {HelmetProvider} from 'react-helmet-async';

import {MainRouter} from './modules';
import {
  userState,
  currentDeviceState,
  userDevicesState,
  t,
  tb,
  bt,
} from './recoil/user/userAtom';
import {lastMsgState} from './recoil/socket/socketAtom';
import {apiRootState} from './recoil/rootAtom';
import {
  FIREBASE_CONFIG,
  API_URL,
  REQUEST_PROTO,
  REQUEST_PORT,
  CDN_URL,
  FACEBOOK_ID,
  PACKAGE_ID,
  APPLE_ID,
  DEPLOYMENT_ENV,
} from './constants';
import {theme} from './Theme';
import {
  initializeAnalytics,
  setFirebaseUserId,
  fireLogin,
  setFirebaseUserProperties,
} from './util/Analytics';
import {
  removeFooterState,
  removeHeaderState,
} from './recoil/removeAppBar/removeAppBarAtom';
import {initUserFromWebView} from './util/WebViewUtils';
import 'firebaseui/dist/firebaseui.css';
import './App.css';
import {initializeMetaData} from '@bidmii/common/lib/util/MetaDataUtils';
import {BuildEnvironment} from '@bidmii/common/lib/server/IServerApi';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

setDefaultHeaders({
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  'x-forwarded-proto': REQUEST_PROTO,
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  'x-forwarded-port': REQUEST_PORT,
  'new-fetch-request': 'true',
});

initializeMetaData({
  onServer: false,
  appUrl: `https://${window.location.hostname}`,
  appName: 'Bidmii',
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
  cdnUrl: CDN_URL,
  facebookAppId: FACEBOOK_ID,
  androidPackage: PACKAGE_ID,
  appleAppStoreId: APPLE_ID,
  nativeAppLaunchUrl: 'https://app.bidmii.com',
  environment: DEPLOYMENT_ENV as BuildEnvironment,
});

export const firebaseApp = initializeApp(FIREBASE_CONFIG);
export const firebaseAuth = getAuth(firebaseApp);
getPerformance(firebaseApp);
const remoteConfig = getRemoteConfig(firebaseApp);
export const firestoreDb = getFirestore(firebaseApp);

const App = (props: $TSFixMe) => {
  const [systemStartTime] = useState(new Date().getTime());
  const [, setApiRoot] = useRecoilState(apiRootState);
  const [, setLastMsg] = useRecoilState(lastMsgState);
  const [, setRemoveHeader] = useRecoilState(removeHeaderState);
  const [, setRemoveFooter] = useRecoilState(removeFooterState);
  const currentDevice = useRecoilValue(currentDeviceState);
  const [userDevices, setUserDevices] = useRecoilState(userDevicesState);
  const [user, setUser] = useRecoilState(userState);
  // Note, the below code is a stupid hack used to block people from certain time zones reverse engineering Bidmii
  const tvalue = useRecoilValue(t);
  const [btvalue, setBtvalue] = useRecoilState(bt);
  const [tbvalue, setTbvalue] = useRecoilState(tb);
  // End of stupid code locking time zones
  const [enableLogrocket, setEnableLogrocket] = useState(false);
  const [originalLocation] = useState(window.location.pathname);

  useEffect(() => {
    fetchRequest(`${API_URL}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      priority: 'high',
    })
      .then((res) => res.json())
      .then((res) => {
        setApiRoot(res);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* STORE FIREBASE TOKEN (f) IN SESSIONSTORAGE */
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    /* STORE FIREBASE TOKEN (f) IN SESSIONSTORAGE */
    const f = searchParams.get('f');
    if (f) {
      sessionStorage.setItem('Firebase-Authorization', f);
      if (!user?.initialised) {
        initUserFromWebView(f, (res: $TSFixMe) => setUser(res));
      }
      setRemoveHeader(true);
      setRemoveFooter(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (btvalue.includes(tvalue)) {
      setTbvalue(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btvalue]);

  /* INIT FIREBASE */
  useEffect(() => {
    initializeAnalytics();
    firebaseAuth.onAuthStateChanged((authUser) => {
      if (authUser) {
        fireLogin(authUser);
        setFirebaseUserId(authUser.uid);
      } else {
        setFirebaseUserId(null);
      }
    });
    isSupported().then((supported) => {
      const isBot =
        ['GoogleBot', 'BingBot', 'AhrefsBot'].includes(navigator.userAgent) ||
        navigator.userAgent.includes('Bot') ||
        navigator.userAgent.includes('rawler');

      if (supported) {
        remoteConfig.settings.minimumFetchIntervalMillis = 600000;
        fetchAndActivate(remoteConfig)
          .then(() => {
            const log_rocket_enabled = getValue(
              remoteConfig,
              'log_rocket_enabled',
            );
            if (log_rocket_enabled.asBoolean() && !isBot) {
              setEnableLogrocket(true);
            }
            const remotebtValues = getValue(remoteConfig, 'bt');
            if (remotebtValues) {
              setBtvalue(remotebtValues.asString().split(','));
            }
          })
          .catch((err) => {
            process.env.NODE_ENV === 'development' && console.error(err);
            Sentry.withScope(function (scope) {
              scope.setTag('section', 'App.tsx line: 177');
              Sentry.captureException(err);
            });
          });
      } else {
        const log_rocket_enabled = process.env.REACT_APP_ENABLE_LOG_ROCKET;
        if (log_rocket_enabled === '1' && !isBot) {
          setEnableLogrocket(true);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* END INIT FIREBASE */

  /* INIT FIREBASE AUTH */
  if (!firebaseui.auth.AuthUI.getInstance()) {
    // eslint-disable-next-line no-new
    new firebaseui.auth.AuthUI(firebaseAuth);
  }
  /* END INIT FIREBASE AUTH */

  useEffect(() => {
    if (tbvalue) {
      setInterval(function () {
        // @ts-ignore
        if (window.location.pathname !== originalLocation) {
          // @ts-ignore
          window.location.pathname = originalLocation;
        }
      }, 2000);
    }
  }, [tbvalue, originalLocation]);

  const searchParams = new URLSearchParams(window.location.search);

  /* STORE REFERRAL TOKEN (r) IN LOCALSTORAGE */
  const r = searchParams.get('r');
  if (r) {
    localStorage.setItem('r', r);
  }

  if (searchParams.get('utm_medium') !== null) {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    localStorage.setItem('utm_medium', searchParams.get('utm_medium'));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    localStorage.setItem('utm_source', searchParams.get('utm_source'));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    localStorage.setItem('utm_term', searchParams.get('utm_term'));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    localStorage.setItem('utm_campaign', searchParams.get('utm_campaign'));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    localStorage.setItem('utm_content', searchParams.get('utm_content'));
  }
  /* END STORE REFERRAL TOKEN IN LOCALSTORAGE */

  /* Listen for last message from firestore */
  useEffect(() => {
    if (
      !user ||
      !user.uid ||
      process.env.REACT_APP_FORCE_WEB_VIEW === '1' ||
      (window as $TSFixMe).ReactNativeWebView
    ) {
      return () => {};
    }
    const appNotificationsRef = doc(
      collection(firestoreDb, 'app-notifications'),
      user.uid,
    );
    setDoc(appNotificationsRef, {}, {merge: true});
    try {
      const unsub = onSnapshot(appNotificationsRef, (snapshot) => {
        const data = snapshot.data();
        if (
          data &&
          data?.timestamp &&
          Number(data.timestamp) > systemStartTime
        ) {
          process.env.NODE_ENV === 'development' &&
            console.log('Received lastMsg data!!!!!');
          process.env.NODE_ENV === 'development' && console.log(data);
          setLastMsg(data);
        }
      });
      return () => {
        unsub ? unsub() : () => {};
      };
    } catch (e) {
      process.env.NODE_ENV === 'development' && console.log('Caught it!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      setFirebaseUserProperties({service_plan: user?.servicePlan || 'FREE'});
    }
  }, [user]);

  useEffect(() => {
    if (currentDevice && Array.isArray(userDevices)) {
      const device = userDevices.find((d) => d.fcmToken === currentDevice);
      if (device && !device.online) {
        securedFetchRequest(
          `${API_URL}users/fcmtoken/toggleonline/${device.uid}/true`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
          },
        ).catch((err) => {
          process.env.NODE_ENV === 'development' && console.error(err);
          Sentry.withScope(function (scope) {
            scope.setTag('section', 'App.tsx line: 290');
            Sentry.captureException(err);
          });
        });
      } else if (!device) {
        const deviceAndFcmInformation = {
          online: true,
          deviceType: 'BROWSER',
          fcmToken: currentDevice,
          deviceUUID: null,
        };
        securedFetchRequest(`${API_URL}users/fcmtoken`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(deviceAndFcmInformation),
        })
          .then((res) => res.json())
          .then((data) => {
            (deviceAndFcmInformation as $TSFixMe).uid = data;
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
            setUserDevices([...userDevices, deviceAndFcmInformation]);
          })
          .catch((error) => {
            process.env.NODE_ENV === 'development' && console.log(error);
            Sentry.withScope(function (scope) {
              scope.setTag('section', 'App.tsx line: 317');
              Sentry.captureException(error);
            });
          });
      }
    }
  }, [userDevices, setUserDevices, currentDevice]);

  /* ENABLE LOG ROCKET AND SENTRY MONITORING */
  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
      });
    }
  }, []);

  useEffect(() => {
    if (enableLogrocket) {
      process.env.NODE_ENV === 'development' &&
        console.log('ENABLING LOGROCKET MONITORING');
      LogRocket.init('pimcf6/bidmii-beta');
    }
  }, [enableLogrocket]);

  // Block context menu in production
  if (enableLogrocket) {
    window.document.oncontextmenu = () => false;
  }

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <HelmetProvider>
              <BrowserRouter>
                <Switch>
                  <Route path="/" component={MainRouter} />
                  <Route render={() => <h1>Page not found</h1>} />
                </Switch>
              </BrowserRouter>
            </HelmetProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
};

export default App;
