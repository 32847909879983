import {userState} from '../recoil/user/userAtom';
import {useSetRecoilState} from 'recoil';
import {securedFetchRequest} from '@bidmii/common/lib/util/FetchRequest';
import {API_URL} from '../constants';
import * as Sentry from '@sentry/react';

export const useRefreshUserState = () => {
  const setUser = useSetRecoilState(userState);

  const refreshUser = () => {
    return securedFetchRequest(`${API_URL}/user`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((res) => {
        res.initialised = true;
        setUser(res);
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' && console.error(err);
        Sentry.withScope(function (scope) {
          scope.setTag('section', 'useRefreshUserState.tsx refreshUser()');
          Sentry.captureException(err);
        });
      });
  };

  return refreshUser;
};

export default useRefreshUserState;
