import React, {useState, useEffect} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import {Switch, Route, useHistory} from 'react-router-dom';
import {API_URL, SERVER_URL} from '../../constants';
import {
  resetSecuredFetchRequest,
  securedFetchRequest,
} from '@bidmii/common/lib/util/FetchRequest';
import ProtectedRoute from '../../common/ProtectedRoute';
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';
import {
  userState,
  userStatsState,
  userProductsState,
  firestoreUserState,
  miscUserDetailsState,
  userDislikesState,
} from '../../recoil/user/userAtom';
import LogRocket from 'logrocket';
import {userLikesState, userDevicesState} from '../../recoil/user/userAtom';
import * as Sentry from '@sentry/react';
import {apiRootState} from '../../recoil/rootAtom';
import {lastMsgState} from '../../recoil/socket/socketAtom';
import {authUserState} from '../../recoil/auth/AuthRecoil';
import useRecordSession from './hooks/useRecordSession';
import {leftMenuOpenState} from '../../recoil/navigation/NavigationRecoil';
import {
  filterValuesState,
  savedProjectsFilterState,
} from '../../recoil/filterValues/filterValuesAtom';
import {
  LazyBidmiiProLandingPage,
  LazyReviewWizard,
  LazyNewProjectWizard,
  LazySupport,
  LazyCategoriesList,
  LazyTrialSetup,
  LazyLocationsList,
  LazySupportPopup,
  LazyMain,
  LazyMainContainer,
  LazyMarketingPage,
  LazyProWebsite,
  LazyRequestForProposalsList,
  LazyLogin,
} from '../../common/LazyLoader';
import {firebaseAuth, firestoreDb} from '../../App';
import {signOut} from 'firebase/auth';
import {collection, doc, getDoc, setDoc} from 'firebase/firestore';
import useRefreshUserState from '../../common/useRefreshUserState';

const MainRouter = () => {
  const [user, setUser] = useRecoilState(userState);
  const lastMsg = useRecoilValue(lastMsgState);
  const [, setLoading] = useState(true);
  const history = useHistory();
  const setUserLikes = useSetRecoilState(userLikesState);
  const setUserDislikes = useSetRecoilState(userDislikesState);
  const setUserStats = useSetRecoilState(userStatsState);
  const setUserProducts = useSetRecoilState(userProductsState);
  const setUserDevices = useSetRecoilState(userDevicesState);
  const setMiscUserDetails = useSetRecoilState(miscUserDetailsState);
  const setAuthUser = useSetRecoilState(authUserState);
  const apiRoot = useRecoilValue(apiRootState);
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
  const recordSession = useRecordSession();
  const setLeftMenuOpen = useSetRecoilState(leftMenuOpenState);
  const setSavedProjectsFilter = useSetRecoilState(savedProjectsFilterState);
  const setFirestoreUser = useSetRecoilState(firestoreUserState);
  const setFilterValues = useSetRecoilState(filterValuesState);
  const refreshUser = useRefreshUserState();

  useEffect(() => {
    try {
      const type = (lastMsg as $TSFixMe)?.type;
      const model = (lastMsg as $TSFixMe)?.model;

      if (type === 'user_banned') {
        signOut(firebaseAuth)
          .then(() => {
            sessionStorage.clear();
            localStorage.clear();
            // eslint-disable-next-line no-alert
            window.alert(
              'Your account has been temporarily disabled, please contact your administrator at 416 628 1553',
            );
            setUser(null as any);
            setSavedProjectsFilter(false);
            history.push('/login');
          })
          .catch((err) => {
            console.warn(err);
            Sentry.withScope(function (scope) {
              scope.setTag('section', 'MainRouter.tsx useEffect()');
              Sentry.captureException(err);
            });
          });
      } else if (model === 'User' && type === 'update') {
        refreshUser();
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  }, [lastMsg, history, setUser, setSavedProjectsFilter, refreshUser]);

  useEffect(() => {
    if (apiRoot != null) {
      firebaseAuth.onAuthStateChanged((authUser) => {
        handleUserStateChange(authUser);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiRoot]);

  useEffect(() => {
    if (
      localStorage.getItem('initNewUser') ||
      sessionStorage.getItem('isNewUser')
    ) {
      if (window.location.pathname.indexOf('/new/user') === -1) {
        if (
          localStorage.getItem('anonymousProjectCreated') !== null ||
          localStorage.getItem('bidmiiProUid')
        ) {
          process.env.NODE_ENV === 'development' &&
            console.log('MainRouter 100');
          history.push('/new/user/wizard/2');
        } else if (localStorage.getItem('bidmiiProPurchased') === '1') {
          process.env.NODE_ENV === 'development' &&
            console.log('MainRouter 103');
          history.push('/new/user/wizard/contractor/3');
        } else {
          process.env.NODE_ENV === 'development' &&
            console.log('MainRouter 106');
          history.push('/new/user/wizard/1');
        }
      }
      return;
    }

    if (
      user &&
      user.id &&
      !localStorage.getItem('isAnonymous') &&
      !localStorage.getItem('initNewUser') &&
      !sessionStorage.getItem('isNewUser')
    ) {
      setLoading(false);
      let userFocus =
        user.userType && user.userType === 'BIDDER' ? '/rfps' : '/myRfps';
      const urlSearchParams = new URLSearchParams(window.location.search);
      const redirect = urlSearchParams.get('redirect');

      if (user.userType === 'REQUESTER') {
        if (!user.hasAgreedToTerms) {
          userFocus = '/new/user/wizard/2?incomplete=true';
        } else if (localStorage.getItem('bidmiiProUid')) {
          process.env.NODE_ENV === 'development' &&
            console.log('MainRouter 130');
          history.push(`${localStorage.getItem('bidmiiProUid')}?migrate=true`);
          return;
        }
      } else if (user.userType === 'BIDDER') {
        if (!user.hasAgreedToTerms) {
          userFocus = '/new/user/wizard/contractor/3?incomplete=true';
        }
      } else if (localStorage.getItem('bidmiiProUid')) {
        process.env.NODE_ENV === 'development' && console.log('MainRouter 139');
        history.push('/new/user/wizard/2');
        return;
      } else {
        userFocus = '/new/user/wizard/1';
      }
      if (
        window.location.pathname.indexOf('/new/user') === -1 &&
        !user.hasAgreedToTerms &&
        window.location.pathname !== '/login'
      ) {
        process.env.NODE_ENV === 'development' && console.log('MainRouter 150');
        history.push(userFocus);
      } else if (
        !redirect &&
        !sessionStorage.getItem('signInSuccessUrl') &&
        window.location.pathname !== '/my/profile/dashboard' &&
        user.userType === 'BIDDER' &&
        (window.location.pathname === '/communityrfps' ||
          window.location.pathname === '/login')
      ) {
        process.env.NODE_ENV === 'development' && console.log('MainRouter 160');
        history.push(userFocus);
        // } else if (
        //   user.servicePlan === 'BIDMII_PRO_CORE' &&
        //   firestoreUser &&
        //   !firestoreUser.proSetupComplete &&
        //   user.hasAgreedToTerms
        // ) {
        //   history.push('/bidmii-pro/setup');
      } else if (
        sessionStorage.getItem('signInSuccessUrl') ||
        (redirect && !(window as $TSFixMe).OVERRIDE_USER_ID)
      ) {
        const signInSuccessUrl = sessionStorage.getItem('signInSuccessUrl');
        sessionStorage.removeItem('signInSuccessUrl');
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        history.push(redirect || signInSuccessUrl);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const migrateAnonymousUser = (userObject: $TSFixMe) => {
    securedFetchRequest(`${API_URL}/user`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userType: localStorage.getItem('bidmiiProPurchased')
          ? 'BIDDER'
          : 'REQUESTER',
        uid: localStorage.getItem('anonymousUid'),
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (localStorage.getItem('anonymousUid')) {
          localStorage.removeItem('anonymousUid');
          localStorage.removeItem('isAnonymous');
          localStorage.removeItem('anonymousProjectCreated');
          setUser({...userObject, ...res});
        }
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' && console.error(err);
        setLoading(false);

        Sentry.withScope(function (scope) {
          scope.setUser({
            uid: user?.uid,
            firstName: user?.firstName,
          });
          scope.setTag('section', 'create_user_step_2');
          Sentry.captureException(err);
        });
      });
  };

  const createUser = (currentUser: $TSFixMe) => {
    setLoading(true);

    const displayName = currentUser?.displayName;
    let firstName = '';
    let lastName = '';
    if (displayName != null && displayName.length > 0) {
      try {
        firstName = currentUser.displayName.split(' ')[0];
        lastName =
          currentUser.displayName.split(' ').length > 1
            ? currentUser.displayName.split(' ')[1]
            : '';
      } catch (e) {}
    }

    sessionStorage.setItem('firstName', firstName);
    sessionStorage.setItem('lastName', lastName);
    sessionStorage.setItem('email', currentUser?.email);

    const newUser = {
      password: '',
      firstName: firstName,
      lastName: lastName,
      email: currentUser.email,
    };

    const code = localStorage.getItem('r');

    if (
      localStorage.getItem('anonymousProjectCreated') ||
      code === 'REALTOR' ||
      localStorage.getItem('bidmiiProUid')
    ) {
      (newUser as $TSFixMe).userType = 'REQUESTER';
    }

    if (![null, 'HOMEOWNER', 'CONTRACTOR'].includes(code)) {
      (newUser as $TSFixMe).code = code;
    }

    return securedFetchRequest(`${API_URL}/users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newUser),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        localStorage.removeItem('initNewUser');
        sessionStorage.removeItem('isNewUser');
        res.initialised = true;
        setUser(res);
        if (
          localStorage.getItem('anonymousProjectCreated') !== null ||
          localStorage.getItem('bidmiiProUid')
        ) {
          history.push('/new/user/wizard/2');
        } else if (localStorage.getItem('bidmiiProPurchased') === '1') {
          history.push('/new/user/wizard/contractor/3');
        } else {
          history.push('/new/user/wizard/1');
        }
      })
      .catch((err) => {
        process.env.NODE_ENV === 'development' && console.error(err);

        setLoading(false);
        localStorage.removeItem('initNewUser');
        sessionStorage.removeItem('isNewUser');
        if (
          localStorage.getItem('anonymousProjectCreated') !== null ||
          localStorage.getItem('bidmiiProUid')
        ) {
          history.push('/new/user/wizard/2');
        } else if (localStorage.getItem('bidmiiProPurchased') === '1') {
          history.push('/new/user/wizard/contractor/3');
        } else {
          history.push('/new/user/wizard/1');
        }

        Sentry.withScope(function (scope) {
          scope.setUser({
            uid: currentUser.uid,
            firstName: currentUser.firstName,
          });
          scope.setTag('main', 'main_router');
          Sentry.captureException(err);
        });
      });
  };

  const handleUserStateChange = (authUser: $TSFixMe) => {
    setAuthUser(authUser);
    resetSecuredFetchRequest(
      authUser ? (forceRefresh) => authUser.getIdToken(forceRefresh) : null,
    );
    if (
      localStorage.getItem('initNewUser') ||
      sessionStorage.getItem('isNewUser')
    ) {
      if (window.location.pathname.indexOf('/new/user') === -1) {
        if (
          localStorage.getItem('anonymousProjectCreated') !== null ||
          localStorage.getItem('bidmiiProUid')
        ) {
          history.push('/new/user/wizard/2');
        } else if (localStorage.getItem('bidmiiProPurchased') === '1') {
          history.push('/new/user/wizard/contractor/3');
        } else {
          history.push('/new/user/wizard/1');
        }
      } else {
        return;
      }
    }

    if (authUser) {
      if (authUser?.displayName) {
        const firstName = authUser.displayName.split(' ')[0];
        const lastName =
          authUser.displayName.split(' ').length > 1
            ? authUser.displayName.split(' ')[1]
            : '';
        sessionStorage.setItem('firstName', firstName);
        sessionStorage.setItem('lastName', lastName);
      }

      if (authUser.email) {
        sessionStorage.setItem('email', authUser.email);
      }

      authUser.getIdToken().then((token: $TSFixMe) => {
        const tokenExpiry = new Date();
        tokenExpiry.setHours(tokenExpiry.getHours() + 1);
        sessionStorage.setItem('Firebase-Authorization', token);
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Date' is not assignable to param... Remove this comment to see the full error message
        sessionStorage.setItem('Token-Expiry', tokenExpiry);
        sessionStorage.setItem('uid', authUser.uid);
        //postSessionData();

        if (
          sessionStorage.getItem('isNewUser') === '1' &&
          !localStorage.getItem('initNewUser')
        ) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
          localStorage.setItem('initNewUser', 1);
          createUser(authUser);
          return;
        }

        // @ts-ignore
        const userDocRef = doc(collection(firestoreDb, 'users'), authUser.uid);

        getDoc(userDocRef)
          .then((doc) => {
            if (doc.exists()) {
              const data = doc.data();
              setFirestoreUser(data);
              if (data?.filters) {
                setFilterValues(data.filters);
              }
            } else {
              setDoc(userDocRef, {email: authUser.email}, {merge: true});
              setFirestoreUser({email: authUser.email});
              process.env.NODE_ENV === 'development' &&
                console.log('Firestore user doc does not exist');
            }
          })
          .catch((err: any) => {
            process.env.NODE_ENV === 'development' && console.error(err);
            Sentry.withScope(function (scope) {
              scope.setTag('section', 'MainRouter.tsx handleUserStateChange()');
              Sentry.captureException(err);
            });
          });

        const options = {
          headers: {
            'Content-Type': 'application/json',
          },
        };

        if (!authUser.isAnonymous) {
          securedFetchRequest(
            `${
              // @ts-ignore
              apiRoot._links.currentUser.href
            }?view=lite&code=${authUser.uid.substr(0, 10)}`,
            {
              ...options,
              priority: 'high',
            },
          )
            .then((userRes) => userRes.json())
            .then((userRes) => {
              userRes.initialised = true;
              setUser(userRes);
              if (
                process.env.NODE_ENV !== 'development' && // JK: Disable log rocket while in development
                process.env.REACT_APP_ENABLE_LOG_ROCKET === '1'
              ) {
                LogRocket.getSessionURL(function (sessionURL) {
                  recordSession(userRes, sessionURL);
                });
              }

              localStorage.setItem(
                'user',
                JSON.stringify({
                  id: userRes.id,
                  name: userRes.name,
                  userType: userRes.userType,
                  imgAttachmentRef: userRes.imgAttachmentRef,
                  hasAgreedToTerms: userRes.hasAgreedToTerms,
                  lat: userRes.lat,
                  lng: userRes.lng,
                  _links: userRes._links,
                }),
              );

              if (userRes._links && userRes._links.stats.href) {
                securedFetchRequest(userRes._links.stats.href, {
                  ...options,
                  priority: 'high',
                })
                  .then((res) => res.json())
                  .then((res) => {
                    setUserStats(res);
                  })
                  .catch((err) => {
                    console.error(err);
                    Sentry.withScope(function (scope) {
                      scope.setTag(
                        'section',
                        'MainRouter.tsx handleUserStateChange()',
                      );
                      Sentry.captureException(err);
                    });
                  });
              }

              if (userRes._links && userRes._links.browserDevices) {
                securedFetchRequest(userRes._links.browserDevices.href, {
                  ...options,
                  priority: 'low',
                })
                  .then((res) => res.json())
                  .then((res) => {
                    setUserDevices(res);
                  })
                  .catch((err) => {
                    process.env.NODE_ENV === 'development' &&
                      console.error(err);
                    Sentry.withScope(function (scope) {
                      scope.setTag(
                        'section',
                        'MainRouter.tsx handleUserStateChange()',
                      );
                      Sentry.captureException(err);
                    });
                  });
              }

              if (userRes._links && userRes._links.likes.href) {
                securedFetchRequest(userRes._links.likes.href, {
                  ...options,
                  priority: 'low',
                })
                  .then((res) => res.json())
                  .then((res) => {
                    setUserLikes(new Set(res));
                  })
                  .catch((err) => {
                    console.error(err);
                    Sentry.withScope(function (scope) {
                      scope.setTag(
                        'section',
                        'MainRouter.tsx handleUserStateChange()',
                      );
                      Sentry.captureException(err);
                    });
                  });
              }
              if (userRes.userType === 'BIDDER') {
                securedFetchRequest(`${API_URL}/users/dislikes`, {
                  ...options,
                  priority: 'low',
                })
                  .then((res) => res.json())
                  .then((res) => {
                    setUserDislikes(new Set(res));
                  })
                  .catch((err) => {
                    process.env.NODE_ENV === 'development' &&
                      console.error(err);
                    Sentry.withScope(function (scope) {
                      scope.setTag(
                        'section',
                        'MainRouter.tsx handleUserStateChange()',
                      );
                      Sentry.captureException(err);
                    });
                  });
              }
              // TODO: refactor to be pageable
              if (userRes._links && userRes._links.products.href) {
                securedFetchRequest(`${API_URL}/purchases?page=0`, {
                  ...options,
                  priority: 'low',
                })
                  .then((res) => res.json())
                  .then((res) => {
                    if (res._embedded) {
                      setUserProducts(res._embedded.models);
                    }
                  })
                  .catch((err) => {
                    process.env.NODE_ENV === 'development' &&
                      console.error(err);
                    Sentry.withScope(function (scope) {
                      scope.setTag(
                        'section',
                        'MainRouter.tsx handleUserStateChange()',
                      );
                      Sentry.captureException(err);
                    });
                  });
              }

              securedFetchRequest(`${API_URL}/user/user-details`, {
                ...options,
                priority: 'low',
              })
                .then((res) => res.json())
                .then((res) => {
                  if (res._embedded) {
                    setMiscUserDetails(res._embedded.models);
                  }
                })
                .catch((err) => {
                  process.env.NODE_ENV === 'development' && console.error(err);
                  Sentry.withScope(function (scope) {
                    scope.setTag(
                      'section',
                      'MainRouter.tsx handleUserStateChange()',
                    );
                    Sentry.captureException(err);
                  });
                });

              // @ts-ignore
              securedFetchRequest(`${apiRoot._links.currentUser.href}`, {
                ...options,
                priority: 'high',
              })
                .then((res) => res.json())
                .then((res) => {
                  res.initialised = true;
                  setUser(res);
                })
                .catch((err) => {
                  process.env.NODE_ENV === 'development' && console.error(err);
                  Sentry.withScope(function (scope) {
                    scope.setTag(
                      'section',
                      'MainRouter.tsx handleUserStateChange()',
                    );
                    Sentry.captureException(err);
                  });
                });

              if (
                process.env.NODE_ENV !== 'development' && // JK: Disable log rocket while in development
                process.env.REACT_APP_ENABLE_LOG_ROCKET === '1'
              ) {
                LogRocket.identify(userRes.id, {
                  name: userRes.name,
                  email: userRes.email,
                  userType: userRes.userType,
                });
              }

              if (localStorage.getItem('anonymousUid')) {
                migrateAnonymousUser(userRes);
              }

              if (window.outerWidth <= 600) {
                setLeftMenuOpen(false);
              }
            });
        }
      });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      sessionStorage.getItem('Firebase-Authorization') &&
      window.location.pathname === '/incremental'
    ) {
      const params = new URLSearchParams(window.location.search);
      securedFetchRequest(`${API_URL}users/getGoogleAuthToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code: params.get('code'),
        }),
      })
        .then(() => window.close())
        .catch((err) => {
          process.env.NODE_ENV === 'development' && console.error(err);
          Sentry.withScope(function (scope) {
            scope.setTag('section', 'MainRouter.tsx useEffect()');
            Sentry.captureException(err);
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname, user]);

  const renderHomePage = (screenProps: $TSFixMe) => {
    if ((window as $TSFixMe).OVERRIDE_USER_ID) {
      return (
        <LazyMainContainer>
          <LazyProWebsite {...screenProps} />;
        </LazyMainContainer>
      );
    } else {
      if (!user || user.userType !== 'BIDDER') {
        return <LazyMarketingPage container />;
      } else if (user.userType === 'BIDDER') {
        return (
          <LazyMainContainer>
            <LazyRequestForProposalsList {...screenProps} type={'contractor'} />
          </LazyMainContainer>
        );
      }
    }
  };

  return (
    <>
      <CssBaseline />
      <Switch>
        {/* Renders a component which redirects to the project, we are going to update the
            links to bypass this and then we can remove this router path
         */}
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'render' is missing in type '{ path: stri... Remove this comment to see the full error message */}
        <ProtectedRoute path="/review" component={LazyReviewWizard} />
        {/* This route is meant to be rendered in an iFrame from another webpage */}
        <Route
          exact
          path="/createproject"
          render={() => {
            document.addEventListener('chatlio.ready', function (e) {
              (window as $TSFixMe)._chatlio.hide();
            });
            return <LazyNewProjectWizard open={true} iframe={true} />;
          }}
        />
        {/* This was meant to act as a bandaid solution for an error in share urls, remove ASAP pending review */}
        <Route
          path="/share"
          render={() => {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            const shareUrl = `${SERVER_URL.substring(
              0,
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              SERVER_URL.length - 1,
            )}${window.location.pathname}${window.location.search}`;
            window.location.href = shareUrl;
            return null;
          }}
        />
        <Route
          exact
          path="/bidmiiPro"
          // @ts-ignore
          render={() => {
            window.location.href =
              'https://www.bidmii.com/how-it-works-contractor';
          }}
        />
        {/* @ts-expect-error ts-migrate(2741) FIXME: Property 'render' is missing in type '{ exact: tru... Remove this comment to see the full error message */}
        <ProtectedRoute
          exact
          path="/bidmii-pro/trial"
          component={LazyTrialSetup}
        />
        <Route
          exact
          path="/bidmii-pro/canadian-financial"
          component={LazyBidmiiProLandingPage}
        />
        <Route exact path="/login" component={LazyLogin} />
        <Route exact path="/register" render={() => <LazyLogin register />} />
        {/* Used in mobile app WebViews */}
        <Route exact path="/support" component={LazySupport} />
        <Route exact path="/locations/cities" component={LazyLocationsList} />
        <Route
          exact
          path="/locations/cities/:province/:city"
          component={LazyCategoriesList}
        />
        <Route path="/m" render={() => <LazyMarketingPage container />} />
        <Route exact path="/" render={renderHomePage} />
        <Route path="/" component={LazyMain} />
      </Switch>
      {window.outerWidth > 600 && !(window as $TSFixMe).OVERRIDE_USER_ID && (
        <LazySupportPopup />
      )}
    </>
  );
};

export default MainRouter;
