export const SCREEN_NAMES = {
  PRIVACY_SETTINGS: 'privacy_settings',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
  PRIVACY_POLICY: 'privacy_policy',
  REVIEW_WIZARD_CONFIRMATION_DIALOG: 'review_wizard_confirmation_dialog',
  REVIEW_WIZARD_FORM: 'review_wizard_form',
  REVIEW_WIZARD_FINISH: 'review_wizard_finish',
  NEW_PROJECT_WIZARD_CONTEST_PROJECT_SELECTION:
    'new_project_wizard_contest_project_selection',
  NEW_PROJECT_WIZARD_PROJECT_SELECTION_STEP:
    'new_project_wizard_project_selection_step',
  NEW_PROJECT_WIZARD_STEP_ONE: 'new_project_wizard_step_one',
  NEW_PROJECT_WIZARD_STEP_TWO: 'new_project_wizard_step_two',
  NEW_PROJECT_WIZARD_STEP_THREE: 'new_project_wizard_step_three',
  NEW_PROJECT_WIZARD_STEP_FOUR: 'new_project_wizard_step_four',
  NEW_PROJECT_WIZARD_LOGIN: 'new_project_wizard_login',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_STEP_ONE:
    'new_project_wizard_pre_packaged_step_one',
  LOGIN: 'login',
  LOGIN_DIALOG: 'login_dialog',
  SUPPORT: 'support',
  MESSAGING_CENTER: 'messaging_center',
  DASHBOARD_DETAILS: 'dashboard_details',
  DASHBOARD_BADGES: 'dashboard_badges',
  DASHBOARD_MY_TEAM: 'dashboard_my_team',
  DASHBOARD_ACCOUNT_DETAILS_DRAWER: 'dashboard_account_details_drawer',
  PUBLIC_PROFILE_ROUTER: 'public_profile_router',
  RESOURCE_ARTICLE: 'resource_article',
  NEW_USER_WIZARD_STEP_ONE: 'new_user_wizard_step_one',
  NEW_USER_WIZARD_STEP_TWO: 'new_user_wizard_step_two',
  NEW_USER_WIZARD_STEP_TWO_CONTRACTOR: 'new_user_wizard_step_two_contractor',
  NEW_USER_WIZARD_STEP_THREE: 'new_user_wizard_step_three',
  NEW_USER_WIZARD_STEP_THREE_CONTRACTOR:
    'new_user_wizard_step_three_contractor',
  NEW_USER_WIZARD_STEP_FOUR: 'new_user_wizard_step_four',
  NEW_USER_WIZARD_STEP_FOUR_CONTRACTOR: 'new_user_wizard_step_four_contractor',
  NEW_USER_WIZARD_STEP_FIVE_CONTRACTOR: 'new_user_wizard_step_five_contractor',
  NEW_USER_WIZARD_STEP_SIX_CONTRACTOR: 'new_user_wizard_step_six_contractor',
  NEW_USER_WIZARD_BIDMII_PRO_PROMPT: 'new_user_wizard_bidmii_pro_prompt',
  NEW_USER_WIZARD_VERIFICATION_CODE: 'new_user_wizard_verification_code',
  NOT_LOGGED_IN_USER_DIALOG: 'not_logged_in_user_dialog',
  REQUEST_FOR_PROPOSAL_LIST: 'request_for_proposal_list',
  REQUEST_FOR_PROPOSAL_LIST_AWARDED: 'request_for_proposal_list_awarded',
  REQUEST_FOR_PROPOSAL_LIST_MY: 'request_for_proposal_list_my',
  REQUEST_FOR_PROPOSAL_LIST_CONTRACTOR: 'request_for_proposal_list_contractor',
  REQUEST_FOR_PROPOSAL_LIST_COMMUNITY: 'request_for_proposal_list_community',
  REQUEST_FOR_PROPOSAL_LIST_MYPROPOSALS:
    'request_for_proposal_list_myproposals',
  REQUEST_FOR_PROPOSAL_LIST_MYVISITS: 'request_for_proposal_list_myvisits',
  REQUEST_FOR_PROPOSAL_DETAIL_COMMUNITY_DIALOG:
    'request_for_proposal_detail_community_dialog',
  REQUEST_FOR_PROPOSAL_DETAIL_CONTRACTOR_DIALOG:
    'request_for_proposal_detail_contractor_dialog',
  REQUEST_FOR_PROPOSAL_DETAIL_AWARDED_DIALOG:
    'request_for_proposal_detail_awarded_dialog',
  REQUEST_FOR_PROPOSAL_DETAIL_MY_REQUEST_DIALOG:
    'request_for_proposal_detail_my_request_dialog',
  REQUEST_FOR_PROPOSAL_DETAIL_MY_PROPOSAL_DIALOG:
    'request_for_proposal_detail_my_proposal_dialog',
  REQUEST_FOR_PROPOSAL_DETAIL_SITE_VISIT_DIALOG:
    'request_for_proposal_detail_site_visit_dialog',
  CONTRACTOR_PROPOSAL_NEW: 'contractor_proposal_new',
  CONTRACTOR_PROPOSAL_EDIT: 'contractor_proposal_edit',
  CONTRACTOR_PROPOSAL_REVIEW: 'contractor_proposal_review',
  CONTRACTOR_PROPOSAL_PLEDGE: 'contractor_proposal_pledge',
  BIDMII_PRO_DIALOG: 'bidmii_pro_dialog',
  DIRECT_QUOTE_DIALOG: 'direct_quote_dialog',
  PROPOSAL_PAYMENT_DIALOG: 'proposal_payment_dialog',
  PROJECT_COMPLETION_WIZARD_STEP_ONE: 'project_completion_wizard_step_one',
  PROJECT_COMPLETION_WIZARD_STEP_TWO: 'project_completion_wizard_step_two',
  PROJECT_COMPLETION_WIZARD_STEP_THREE: 'project_completion_wizard_step_three',
  PROJECT_COMPLETION_WIZARD_STEP_FOUR: 'project_completion_wizard_step_four',
  HOME: 'home',
  CONTRACTOR_DASHBOARD: 'contractor_dashboard',
  SUBSCRIPTION_PLANS: 'subscription_plans',
  BIDMII_PRO_CHECKOUT: 'bidmii_pro_checkout',
  BIDMII_PRO_CHECKOUT_CDAP: 'bidmii_pro_checkout_cdap',
  ACCOUNTING_ANALYTICS: 'accounting_analytics',
  INVOICES: 'invoices',
  RESOURCES_LIST: 'resources_list',
};

/*
 WARNING - Ensure the mappings do not exceed 40 characters in length
 */

export const CUSTOM_EVENT_NAMES = {
  BIDMII_PRO_CDAP_SIGNUP: 'bidmii_pro_cdap_signup',
  BIDMII_PRO_TRIAL_SIGNUP: 'bidmii_pro_trial_signup',
  BIDMII_PRO_30_DAY_TRIAL_SIGNUP: 'bidmii_pro_30_day_trial_signup',
  BIDMII_PRO_80_DISCOUNT_TRIAL_SIGNUP: 'bidmii_pro_80_discount_trial_signup',

  NEW_USER_WIZARD_COMPLETED: 'new_user_wizard_completed',
  NEW_USER_WIZARD_COMPLETED_CONTRACTOR: 'new_user_wizard_completed_contractor',
  CONTRACTOR_PROPOSAL_SUBMITTED: 'contractor_proposal_submitted',
  NEW_PROJECT_WIZARD_COMPLETED: 'new_project_wizard_completed',

  NEW_PROJECT_WIZARD_TITLE: 'new_project_wizard_title',
  NEW_PROJECT_WIZARD_DESCRIPTION: 'new_project_wizard_description',
  CATEGORIES_MULTI_SELECT: 'categories_multi_select',
  NEW_PROJECT_WIZARD_ADDRESS: 'new_project_wizard_address',
  NEW_PROJECT_WIZARD_PRIVACY: 'new_project_wizard_privacy',

  NEW_PROJECT_WIZARD_PRE_PACKAGED_PAINTING: 'new_project_pre_packaged_painting',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_WINDOW: 'new_project_pre_packaged_window',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_YARD: 'new_project_pre_packaged_yard',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_DRIVEWAY: 'new_project_pre_packaged_driveway',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_BATHROOM: 'new_project_pre_packaged_bathroom',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_FLOORING: 'new_project_pre_packaged_flooring',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_ROOFING: 'new_project_pre_packaged_roofing',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_WINDOW_REPLACEMENT:
    'new_project_pre_packaged_window_replace',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_TRUSSCORE:
    'new_project_pre_packaged_trusscore',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_GARAGE_MAKEOVER:
    'new_project_pre_packaged_garage_makeover',
  NEW_PROJECT_WIZARD_PRE_PACKAGED_DESIGNMII:
    'new_project_pre_packaged_designmii',

  IDENTITY_VERIFICATION_CTA_PROFILE_BANNER:
    'identity_verification_cta_profile_banner',
  IDENTITY_VERIFICATION_WIZARD_COMPLETED:
    'identity_verification_wizard_completed',
  IDENTITY_VERIFICATION_CTA_PROFILE_BADGE:
    'identity_verification_cta_profile_badge',

  TRUSSCORE_CTA_CLICK: 'trusscore_cta_click',
  HOMEWISE_CTA_CLICK: 'homewise_cta_click',
  RUGSOURCE_CTA_CLICK: 'rugsource_cta_click',
  CANADIAN_FINANCIAL_CTA_CLICK: 'canadian_financial_cta_click',
  LENDFLOW_CTA_CLICK: 'lendflow_cta_click',
  HOME_HARDWARE_CTA_CLICK: 'home_hardware_cta_click',
  CAKE_CTA_CLICK: 'cake_cta_click',
  MOVEMATE_CTA_CLICK: 'movemate_cta_click',

  // Q&A
  Q_AND_A_CLICK: 'q_and_a_click',
  Q_AND_A_SEND: 'q_and_a_send',
  Q_AND_A_RESPONSE_CLICK: 'q_and_a_response_click',
  Q_AND_A_RESPONSE_SEND: 'q_and_a_response_send',

  // Homeowner Rfp Lifecycle
  RFP_PROPOSAL_TAB_VIEW_HO: 'rfp_proposal_tab_view_ho',
  RFP_PROPOSAL_VIEW_DETAIL_HO: 'rfp_proposal_view_detail_ho',
  RFP_PROPOSAL_AWARD_VIEW: 'rfp_proposal_award_view',
  RFP_PROPOSAL_CONFIRM_CHECKOUT: 'rfp_proposal_confirm_checkout',
  RFP_PROPOSAL_CHECKOUT_VIEW: 'rfp_proposal_checkout_view',
  RFP_SITE_VISIT_TAB_VIEW_HO: 'rfp_site_visit_tab_view_ho',

  // Request a Quote events
  REQUEST_QUOTE_PRO_WEBSITE_OPEN: 'request_quote_pro_website_open',
  REQUEST_QUOTE_INVITE_CONTRACTOR_OPEN: 'request_quote_invite_contractor_open',
  REQUEST_QUOTE_SHELL_CREATED: 'request_quote_shell_created',
  REQUEST_QUOTE_SENT: 'request_quote_sent',

  // Misc
  DISLIKE_PROJECT_CONFIRMED: 'unlike_rfp_confirmed',
};
