import {
  getAnalytics,
  setUserId,
  setUserProperties,
  logEvent,
  setCurrentScreen,
} from 'firebase/analytics';
import {firebaseApp} from '../App';

let analytics: $TSFixMe;

export {
  SCREEN_NAMES,
  CUSTOM_EVENT_NAMES,
} from '@bidmii/common/lib/util/Analytics';

export const initializeAnalytics = () => {
  try {
    analytics = getAnalytics(firebaseApp);
  } catch {
    process.env.NODE_ENV === 'development' &&
      console.log('firebase analytics not initialized');
  }
};

export const setFirebaseUserId = (id: $TSFixMe) => {
  if (!analytics) {
    return;
  }

  setUserId(analytics, id);
};

// TODO: we might want to use this for user type (i.e. home owner...).
// We can define 25 of these and use them to establish audiences.
// See https://firebase.google.com/docs/analytics/user-properties?platform=web
export const setFirebaseUserProperties = (properties: $TSFixMe) => {
  if (!analytics) {
    return;
  }

  setUserProperties(analytics, properties);
};

export const setFirebaseUserType = (userType: $TSFixMe) => {
  if (!analytics) {
    return;
  }

  setUserProperties(analytics, {user_type: userType});
};

export const fireLogin = (authUser: $TSFixMe) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, 'login', {method: getAuthMethodUsed(authUser)});
};

export const fireSignUp = (authUser: $TSFixMe) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, 'sign_up', {method: getAuthMethodUsed(authUser)});
};

export const fireSearch = (searchTerm: $TSFixMe) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, 'search', {search_term: searchTerm});
};

// TODO: we probably want to mark up most of the application with these.
export const fireSelectContent = (contentType: $TSFixMe, itemId: $TSFixMe) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, 'select_content', {
    content_type: contentType,
    item_id: itemId,
  });
};

// TODO: using these instead of relying on page_view might be a more effective way to cover
// all of the modals and sections of the app.
export const fireScreenView = (screenName: $TSFixMe) => {
  if (!analytics) {
    return;
  }
  process.env.NODE_ENV === 'development' &&
    console.log(`FIRE SCREEN VIEW: ${screenName}`);
  setCurrentScreen(analytics, screenName);
  // @ts-ignore
  logEvent(analytics, 'screen_view', {firebase_screen: screenName});
};

// TODO: do we have this yet?
export const fireShare = (
  method: $TSFixMe,
  contentType: $TSFixMe,
  itemId: $TSFixMe,
) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, 'share', {
    method,
    content_type: contentType,
    item_id: itemId,
  });
};

// TODO: more item detail?
export const firePurchase = (value: $TSFixMe, transactionId: $TSFixMe) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, 'purchase', {
    value,
    currency: 'CAD',
    transaction_id: transactionId,
    items: [{item_id: transactionId, item_name: transactionId}],
  });
};

// TODO: we don't need to use this for everything, perhaps specific
// exceptions of concern for analysis or top-level errors
export const fireException = (description: $TSFixMe, fatal = true) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, 'exception', {description, fatal});
};

export const fireCustomEvent = (
  eventName: $TSFixMe,
  eventParams?: $TSFixMe,
) => {
  if (!analytics) {
    return;
  }

  logEvent(analytics, eventName, eventParams);
};

// Determine the provider id used for this firebase user's login, see
// https://github.com/firebase/FirebaseUI-Android/issues/329#issuecomment-249631614)
// and map it to the format required by the login event, see
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
const getAuthMethodUsed = (authUser: $TSFixMe) => {
  try {
    if (authUser.isAnonymous || authUser.providerData.length === 0) {
      return 'Anonymous';
    }

    const provider = authUser.providerData[0].providerId.replace('.com', '');

    return provider.charAt(0).toUpperCase() + provider.slice(1);
  } catch (e) {
    return 'Anonymous';
  }
};
