import {atom} from 'recoil';

export const filterValuesState = atom({
  key: 'filterValuesState',
  default: {
    locationFilter: null,
    coordsFilter: {lat: null, lng: null},
    statusFilter: null,
    categoriesFilter: null,
    radiusFilter: null,
    pmProjectsOnlyFilter: false,
  },
});

export const savedProjectsFilterState = atom({
  key: 'savedProjectsFilterState',
  default: false,
});
